<template>
  <div class="list-info">
    <top-bar :title="'房屋列表'" :left="true"></top-bar>
    <!-- 列表 -->
    <div class="cont">
      <van-pull-refresh v-model="pullLoading" success-text="刷新成功" @refresh="onPullDownRefresh">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList"
          v-if="dataList.length > 0" offset="10">
          <div class="building">
            <div class="items" v-for="item in dataList" :key="item.id" @click="goInfo(item.id,item.community,item.subarea,item.subareaName,item.fullName)">
              <div class="top">
                <div class="top-left" :class="$isOld == 1? 'isOld34' : ''">{{item.subareaName}}：{{item.fullName}}</div>
                <div class="top-right"
                  :class="{
                  'self-occupation' : item.useType == 1,
                  'rent-out': item.useType == 2,
                  'half': item.useType == 3,
                  'commercial': item.useType == 4,
                  'remove': item.useType == 5,
                  'leisure': item.useType == 6,
                  'unknown': item.useType == 0,
                  'isOld28': $isOld == 1
                  }"
                  >
                  {{item.useTypeName}}
                </div>
              </div>
              <div class="down">
                <div class="down-left">
                  <div class="personal" :class="$isOld == 1? 'isOld32' : ''">
                    <div class="name">户主：{{item.userName || '暂无户主'}}</div>
                    <div class="mobile">{{item.userMobile}}</div>
                    <a v-if="item.userMobile" :href="'tel:'+item.userMobile" @click.stop=""><img class="mobile-icon" :src="require('@/assets/img/newVersion/mobile.png')" /></a>
                  </div>
                  <div class="people-counting" :class="$isOld == 1? 'isOld32' : ''">
                    <div class="live">居住人数：<div class="count">{{item.liveCount}}</div>人</div>
                    <div class="flow">流动人数：<div class="count">{{item.migrantNum}}</div>人</div>
                  </div>
                </div>
                <img class="down-mid" :class="{'isOld60': $isOld == 1}" @click.stop="openQrcode(item.id,item.relationship)" :src="require('@/assets/img/newVersion/qrcode.png')" />
                <img class="down-right" :src="require('@/assets/img/newVersion/right.png')" />
              </div>
            </div>
            <!-- 二维码弹窗 -->
            <van-dialog v-model="qrcodeShow" :show-cancel-button="false" :show-confirm-button="false">
              <div class="qrcode-box">
                <img
                  class="qrcode-close"
                  src="@/assets/img/newVersion/close.png"
                  @click="closeQrcode">
                <van-image class="qrcode-img" :src="qrcodeImg" />
                <div class="qrcode-text">自主登记二维码</div>
              </div>
            </van-dialog>
          </div>
        </van-list>
        <van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import {getImageStream} from '@/utils/index'
import topBar from '@/components/topBar/topBar'
export default {
  name: 'houseManegement',
  data() {
    return {
      page: 0,
      limit: 10,
      submitData: {
        gridId: '',
        subarea: '',
        buildingId: '',
        unitId: '',
        houseId: '',
        useType: '',
        labels: []
      },
      finished: false,
      loading: false,
      pullLoading: false,
      dataList:[],
      qrcodeShow: false,
      qrcodeImg: '',
      type: ''
    }
  },
  components: {
    topBar
  },
  created() {
    this.getDataList()
    this.type = this.$route.query.type
  },
  methods: {
    getDataList() {
      this.page++
      this.$toast.loading({ duration: 0, message: '加载中...', forbidClick: true, });
      this.$http({
        url: this.$http.adornUrl('/wxapp/building/house/list'),
        method: 'GET',
        params: this.$http.adornParams({
          page: this.page,
          limit: this.limit,
          userId: this.$userId,
          orgId: this.$orgId,
          houseId: this.$globalData.userInfo.houseId || undefined,
          loginUserId: this.$globalData.userInfo.userId,
          loginUserOrgId: this.$globalData.userInfo.orgId
        })
      }).then(({data}) => {
        this.$toast.clear()
        if(data && data.code === 0) {
          this.pullLoading = false
          if (this.dataList.length == data.page.totalCount) {
              this.finished = true
          }
          this.dataList = this.dataList.concat(data.page.list.map(item => {
            if(item.useTypeName.length < 4) {
              item.useTypeName = item.useTypeName.slice(0,2) + '房屋'
            }
            if(item.useTypeName == '半自住半出租') {
              item.useTypeName = '半住半租'
            }
            return {...item}
          }))
          // 加载状态结束
          this.loading = false
        }else {
          this.$toast.fail(data.msg)
        }
      })
    },
    onPullDownRefresh() {
      this.finished = false;
      this.dataList = [];
      this.page = 0;
      this.getDataList();
    },
    // 详情
    goInfo(id,community,subarea,subareaName,fullName) {
      let houseName = subareaName + '-' + fullName
      this.$router.push({path: '/peopleList',query: {id,community,subarea,houseName}})
    },
    // 打开二维码弹窗
    openQrcode(houseId,relationship) {
      let isTenant = 0
      if(relationship == 10) {
        isTenant = 1
      }
      this.$http({
        url: this.$http.adornUrl('/wxapp/building/house/getRegisterQrCode'),
        method: 'POST',
        params: this.$http.adornParams({
          community: this.$orgId,
          houseId,
          isTenant,
          userId: this.$userId,
          path: window.location.origin + '/sso'
        })
      }).then(({data}) => {
        if(data && data.code === 0) {
          this.qrcodeImg = ''
          this.qrcodeImg = getImageStream(data.qrCode)
          this.qrcodeShow = true
        }
      })
    },
    // 关闭二维码弹窗
    closeQrcode() {
      this.qrcodeShow = false
    },

  },

}
</script>
<style scoped>
.building {
  width: 750px;
  padding-top: 24px;
  /* 样式qrcode-dialog的父相对定位 */
  position: relative;
}
.items {
  min-height: 244px;
  background: #FFFFFF;
  padding: 0 30px;
  margin-bottom: 16px;
}
.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 92px;
  border-bottom: 0.5PX solid rgba(0,0,0,0.1);
}
.top-left {
  width: 500px;
  font-size: 30px;
  font-family: 'PingFangSC-Semibold';
  font-weight: 600;
  color: #333333;
}
.top-right {
  min-width: 136px;
  height: 52px;
  /* background: rgba(78,124,245,0.12); */
  border-radius: 8px;
  font-size: 24px;
  font-family: 'PingFangSC-Regular';
  font-weight: 400;
  /* color: #4E7CF5; */
  line-height: 52px;
  text-align: center;
  padding: 0 20px;
}
.self-occupation {
  /* 自住 */
  background: rgba(78,124,245,0.12);
  color: #4E7CF5;
}
.rent-out {
  /* 出租 */
  background-color: rgba(223,137,30,0.1);
  color: #DF891E;
}
.leisure {
  /* 空闲 */
  background: rgba(181,80,186,0.1);
  color: #B550BA;
}
.commercial {
   /* 商用 */
  background: rgba(43,143,50,0.1);
  color: #2B8F32;
}
.remove {
  /* 拆迁 */
  background: rgba(233,86,75,0.1);
  color: #E9564B;
}
.unknown {
  /* 未知 */
  background: rgba(102,102,102,0.1);
  color: #666666;
}
.half {
  /* 半自住半出租 */
  background: rgba(223,137,30,0.1);
  color: #DF891E;
}
.down {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 22px;
}
.personal {
  font-size: 28px;
  font-family: 'PingFangSC-Medium';
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}
.name {
  color: #333333;
}
.mobile {
  color: #4581F8;
  margin-left: 24px;
  margin-right: 40px;
}
.mobile-icon {
  width: 40px;
  height: 40px;
}
.people-counting {
  display: flex;
  align-items: center;
  font-size: 28px;
  font-family: 'PingFangSC-Regular';
  font-weight: 400;
  color: #666666;
}
.live {
  margin-right: 40px;
}
.count {
  font-family: 'PingFang Bold';
  font-weight: 600;
  display: inline;
  color: #333333;
}
.down-mid {
  width: 52px;
  height: 52px;
}
.down-right {
  width: 16px;
  height: 28px;
}
.van-dialog {
  width: 560px;
  border-radius: 16px;
}
.qrcode-box {
  /* width: 560px; */
  height: 591px;
  background: #FFFFFF;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  align-items: center;
  /* 样式qrcode-close的父 相对定位 */
  position: relative;
}
.qrcode-close {
  /* 样式qrcode-box的子 绝对定位 */
  position: absolute;
  top: 24px;
  right: 24px;
  width: 36px;
  height: 36px;
}
.qrcode-img {
  width: 360px;
  height: 360px;
  margin-top: 70px;
  margin-bottom: 40px;
}
.qrcode-text {
  font-size: 36px;
  font-family: 'PingFangSC-Medium';
  font-weight: 500;
  color: #333333;
  margin-bottom: 70px;
}
</style>
