var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list-info"},[_c('top-bar',{attrs:{"title":'房屋列表',"left":true}}),_c('div',{staticClass:"cont"},[_c('van-pull-refresh',{attrs:{"success-text":"刷新成功"},on:{"refresh":_vm.onPullDownRefresh},model:{value:(_vm.pullLoading),callback:function ($$v) {_vm.pullLoading=$$v},expression:"pullLoading"}},[(_vm.dataList.length > 0)?_c('van-list',{attrs:{"finished":_vm.finished,"finished-text":"没有更多了","offset":"10"},on:{"load":_vm.getDataList},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('div',{staticClass:"building"},[_vm._l((_vm.dataList),function(item){return _c('div',{key:item.id,staticClass:"items",on:{"click":function($event){return _vm.goInfo(item.id,item.community,item.subarea,item.subareaName,item.fullName)}}},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"top-left",class:_vm.$isOld == 1? 'isOld34' : ''},[_vm._v(_vm._s(item.subareaName)+"："+_vm._s(item.fullName))]),_c('div',{staticClass:"top-right",class:{
                'self-occupation' : item.useType == 1,
                'rent-out': item.useType == 2,
                'half': item.useType == 3,
                'commercial': item.useType == 4,
                'remove': item.useType == 5,
                'leisure': item.useType == 6,
                'unknown': item.useType == 0,
                'isOld28': _vm.$isOld == 1
                }},[_vm._v(" "+_vm._s(item.useTypeName)+" ")])]),_c('div',{staticClass:"down"},[_c('div',{staticClass:"down-left"},[_c('div',{staticClass:"personal",class:_vm.$isOld == 1? 'isOld32' : ''},[_c('div',{staticClass:"name"},[_vm._v("户主："+_vm._s(item.userName || '暂无户主'))]),_c('div',{staticClass:"mobile"},[_vm._v(_vm._s(item.userMobile))]),(item.userMobile)?_c('a',{attrs:{"href":'tel:'+item.userMobile},on:{"click":function($event){$event.stopPropagation();}}},[_c('img',{staticClass:"mobile-icon",attrs:{"src":require('@/assets/img/newVersion/mobile.png')}})]):_vm._e()]),_c('div',{staticClass:"people-counting",class:_vm.$isOld == 1? 'isOld32' : ''},[_c('div',{staticClass:"live"},[_vm._v("居住人数："),_c('div',{staticClass:"count"},[_vm._v(_vm._s(item.liveCount))]),_vm._v("人")]),_c('div',{staticClass:"flow"},[_vm._v("流动人数："),_c('div',{staticClass:"count"},[_vm._v(_vm._s(item.migrantNum))]),_vm._v("人")])])]),_c('img',{staticClass:"down-mid",class:{'isOld60': _vm.$isOld == 1},attrs:{"src":require('@/assets/img/newVersion/qrcode.png')},on:{"click":function($event){$event.stopPropagation();return _vm.openQrcode(item.id,item.relationship)}}}),_c('img',{staticClass:"down-right",attrs:{"src":require('@/assets/img/newVersion/right.png')}})])])}),_c('van-dialog',{attrs:{"show-cancel-button":false,"show-confirm-button":false},model:{value:(_vm.qrcodeShow),callback:function ($$v) {_vm.qrcodeShow=$$v},expression:"qrcodeShow"}},[_c('div',{staticClass:"qrcode-box"},[_c('img',{staticClass:"qrcode-close",attrs:{"src":require("@/assets/img/newVersion/close.png")},on:{"click":_vm.closeQrcode}}),_c('van-image',{staticClass:"qrcode-img",attrs:{"src":_vm.qrcodeImg}}),_c('div',{staticClass:"qrcode-text"},[_vm._v("自主登记二维码")])],1)])],2)]):_c('van-image',{staticStyle:{"margin-left":"10%","width":"80%"},attrs:{"src":require('@/assets/img/empty.png')}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }